import { useEffect } from "react";
import Layout from "../components/Layout";
import Link from "next/link";
import useSWR from "swr";
import Head from "next/head";
import { useRouter } from "next/router";

import eventiveConfig from "../eventive.config.json";
import api from "../services/api";
import { useAuth } from "../hooks/useAuth";

export default function Index({ initialData }) {
  const router = useRouter();
  const { apiLoaded, singleOrganization } = useAuth();
  const { data, mutate, error } = useSWR(
    `watch/current_tenants${
      singleOrganization ? `?owner=${singleOrganization.id}` : ""
    }`,
    k => api.get(k).then(r => r.data),
    { initialData }
  );
  useEffect(() => {
    if (apiLoaded) mutate(initialData, false);
  }, [apiLoaded]);
  useEffect(() => {
    if (!singleOrganization) {
      // If in non-single-organization mode, redirect back to the Discovery Portal
      router.replace(`/`);
    } else if (singleOrganization && data?.tenants?.length === 1) {
      // Auto-redirect if only a single available event bucket
      router.replace("/[festivalId]", `/filmfest`);
    }
  }, [data]);

  if (
    !data ||
    !singleOrganization ||
    (singleOrganization && data.tenants?.length === 1)
  ) {
    return <Layout loader landing />;
  }

  let { tenants } = data;

  let featuredTenants = [],
    plainTenants = [];

  for (const tenant of tenants || []) {
    if (tenant.image) {
      featuredTenants.push(tenant);
    } else {
      plainTenants.push(tenant);
    }
  }

  return (
    <Layout landing={true}>
      <Head>
        <title>
          {singleOrganization ? singleOrganization.name : "Eventive Virtual"}
        </title>
      </Head>
      <p className="mt-16 text-gray-200 text-lg text-center">
        Welcome! Select a channel to continue.
      </p>
      <div className="flex flex-wrap justify-center items-start mt-6">
        {featuredTenants.map(({ tenant, image, name }) => (
          <Link key={tenant} href="/[festivalId]" as={`/${tenant}`}>
            <a
              key={tenant}
              className="group flex flex-col text-center items-center justify-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 rounded-lg text-white text-center text-lg font-semibold cursor-pointer"
            >
              <div className="h-40 relative cursor-pointer duration-150 transform sm:group-hover:shadow-outline-gray sm:group-hover:scale-105 group-hover:z-10 group-hover:shadow-lg bg-gray-500 rounded-lg transition duration-150">
                <img
                  className="rounded-lg object-cover w-full h-full"
                  src={`${image}?w=300`}
                />
              </div>
              <div
                style={{ transitionProperty: "transform" }}
                className="mt-3 text-gray-200 group-hover:text-white transform duration-150 group-hover:scale-105 group-hover:translate-y-1 duration-150"
              >
                {name}
              </div>
            </a>
          </Link>
        ))}
      </div>
      <div className="flex flex-wrap justify-center mt-6 -m-2">
        {plainTenants.map(({ tenant, name }) => (
          <Link key={tenant} href="/[festivalId]" as={`/${tenant}`}>
            <a
              key={tenant}
              style={{ transitionProperty: "box-shadow" }}
              className="flex text-center items-center justify-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 m-2 bg-gray-700 border-gray-600 p-6 rounded-lg text-white text-center text-lg font-semibold hover:shadow-outline-gray cursor-pointer duration-150"
            >
              <div>{name}</div>
            </a>
          </Link>
        ))}
      </div>
    </Layout>
  );
}

export async function getServerSideProps({ res, locale }) {
  if (res) {
    res.setHeader("Cache-Control", "s-maxage=1, stale-while-revalidate");
  }

  let props;
  try {
    const singleOrganization = eventiveConfig.single_organization || null;
    const data = await api
      .get(
        `watch/current_tenants${
          singleOrganization ? `?owner=${singleOrganization.id}` : ""
        }`
      )
      .then(r => r.data);
    props = { initialData: data };
  } catch (e) {
    props = {};
  }

  return {
    props: { ...props }
  };
}
